<template>
  <div>
    <br />
    <div class="d-flex justify-content-between align-items-center p-0">
      <div class="col-auto">
        <span class="p-2">
          Commande Référence Nº :
          <span class="fw-bold">
            {{ $route.params.reference }}
          </span>
        </span>
      </div>
      <div class="col-auto row">
        
        <div v-if="order.reference" class="col-auto p-1">
          <button @click="$router.push({
            name: 'orders-suppliers-edit',
            params: { reference: order.reference }
          })" class="btn border border-secondary">
            <i class="bi bi-pencil-square text-secondary fs-4"></i> Modifier
          </button>
        </div>
        <div v-if="order.reference" class="col-auto p-1">
          <button @click="print(order.reference)" class="btn border border-secondary ">
            <i class="bi bi-printer text-secondary fs-4"></i> Imprimer
          </button>
        </div>

        <div v-if="order.reference" class="col-auto p-1">
          <button @click="sendByEmail(order.reference)" class="btn border border-secondary ">
            <i class="bi bi-envelope-arrow-up text-secondary fs-4"></i> Envoyer par e-mail
          </button>
        </div>

        <div class="col-auto p-1">
          <button @click="refresh()" class="btn btn border border-secondary">
            <i class="bi bi-arrow-clockwise text-secondary fs-4"></i> Actualiser
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="row align-items-stretch">
      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Client</div>
          <div class="col p-1 fw-bold">
            <span v-if="order.supplier">
              {{ order.supplier.society }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nº de Téléphone</div>
          <div class="col p-1 fw-bold">
            <span v-if="order.supplier">
              {{ order.supplier.phone }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nom Commercial (Société)</div>
          <div class="col p-1 fw-bold">
            <span v-if="order.supplier">
              {{ order.supplier.society }}
            </span>
          </div>
        </div>
      </div>
      <!-- ***** -->
      <div class="col-4 justify-content-between">
        <div class="row">
          <div class="col p-1">Quantité totale</div>
          <div v-if="order.totalQuantity" class="col p-1 fw-bold">
            {{ order.totalQuantity }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total TTC</div>
          <div v-if="order.totalPriceTTC" class="col p-1 fw-bold">
            {{ order.totalPriceTTC }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total HT</div>
          <div v-if="order.totalPriceHT" class="col p-1 fw-bold">
            {{ order.totalPriceHT }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total TVA</div>
          <div v-if="order.totalPriceTVA" class="col p-1 fw-bold">
            {{ order.totalPriceTVA }}
          </div>
        </div>
      </div>
      <!--  -->
      <div class="col-4 justify-content-between">
        <div class="row">
          <div class="col p-1">Total Payé :</div>
          <div class="col p-1 fw-bold">
            {{ order.amountPaid }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Impayé :</div>
          <div class="col p-1 fw-bold">
            {{ order.amountUnpaid }}
          </div>
        </div>
      </div>
    </div>

    <br />
    <div class="row align-items-stretch">
      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">État de livraison</div>
          <div class="col p-1 fw-bold">
            <span v-if="order.supplier"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Adresse de livraison :</div>
          <div class="col p-1 fw-bold">
                {{ order.deliveryAddress }}  
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1"> Adresse de facturation :</div>
          <div class="col p-1 fw-bold">
              {{ order.billingAddress }}   
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Vérifié (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span v-if="order.supplier"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="order.supplier"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié à :</div>
          <div class="col p-1 fw-bold">
            <span v-if="order.supplier"> - </span>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Validé (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span v-if="order.supplier"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="order.supplier"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé à :</div>
          <div class="col p-1 fw-bold">
            <span v-if="order.supplier"> - </span>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Nom</th>
          <th scope="col">Quantité</th>
          <th scope="col">Prix U (TTC)</th>
          <th scope="col">M. Total</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in order.order_items" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ item.item_reference }}
          </td>
          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
            <span v-if="item.remark"> ({{ item.remark }}) </span>
          </td>
          <td>{{ item.quantity }}</td>
          <td class="text-warning">
            {{ item.priceTTC }}
          </td>
          <td class="text-warning">
            {{ item.totalPriceTTC }}
          </td>
          <td class="text-success">
            {{ item.payrollAmount }}
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch(
      "order/showSupplierOrder",
      this.$route.params.reference
    );
  },
  computed: {
    ...mapGetters("order", {
      order: "getSupplierOrder",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch(
        "order/showSupplierOrder",
        this.$route.params.reference
      );
    },

    async print(reference) {
      await this.$store.dispatch("order/printSupplierOrder", reference);
    },

    async sendByEmail(reference) {
      await this.$store.dispatch("order/sendByEmailSupplierOrder", reference);
    },
  },
};
</script>
